/**
 * Site header
 */
.site-header {
    //border-top: 5px solid $brand-color;
    //border-bottom: 1px solid $grey-color-light;
    min-height: 56px;
    //background-color: $background-color-dark;

    // Positioning context for the mobile navigation icon
    position: relative;
    
    img.header-logo {
      margin: 12px 0;
      max-width: 100%;
      width: 300px;
      height: auto;
    }
}

.site-title {
    font-size: 26px;
    line-height: 56px;
    letter-spacing: -1px;
    margin-bottom: 0;
    float: left;

    &,
    &:visited {
        color: $grey-color-dark;
    }
}

.site-nav {
    
    float: right;
    line-height: 56px;
    //font-size: 0.9em;

    .menu-icon {
        display: none;
    }

    .page-link {
        color: $text-color;
        line-height: $base-line-height;
        text-decoration: none;
        
        &:hover {
            color: $link-color;
        }

        // Gaps between nav items, but not on the first one
        &:not(:first-child) {
            margin-left: 12px;
        }
    }

    @include media-query($on-palm + 120px) {
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 26px;
            line-height: 0;
            padding-top: 10px;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: block;
            padding: 5px 10px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    //border-top: 1px solid $grey-color-light;
    //padding: $spacing-unit 0;
    //background-color: $background-color-dark;

    
    
    .wrapper {
        //border-top: 1px dashed $grey-color-light;
        //padding-top: $spacing-unit;
    }
}

.footer-heading {
    font-size: 1.2em;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    font-size: 0.8em;

    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;

    ul.footer-links {
        list-style: none;
        margin: 0;
        li {
            a {
                text-decoration: none;
            }
        }
    }    
}


    


.footer-col-1 {
    width: -webkit-calc(25% - (#{$spacing-unit} / 2));
    width:         calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(25% - (#{$spacing-unit} / 2));
    width:         calc(25% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(50% - (#{$spacing-unit} / 2));
    width:         calc(50% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
    .footer-heading {
        font-size: 1.0em;
    }
}



/**
 * Page content
 */
.page-content {
    //padding: $spacing-unit 0;
    background-color: $background-color-content;
    min-height: 400px;
    
    hr {
        margin: $spacing-unit / 1.5 0;
    }
}

.page-header {
    
}

.page-title {
  font-size: 2.2em;

  @include media-query($on-laptop) {
    font-size: 1.8em;
  }
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 2.2em;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 2em;
    }
}

.page-content,
.post-content {
    
    h1 {
      font-size: 2.0em;
  
      @include media-query($on-laptop) {
        font-size: 1.8em;
      }
    }
  
    h2 {
      font-size: 2em;

      @include media-query($on-laptop) {
        font-size: 1.8em;
      }
    }

    h3 {
      font-size: 1.8em;

      @include media-query($on-laptop) {  
        font-size: 1.6em;
      }
    }

    h4 {
      font-size: 1.5em;

      @include media-query($on-laptop) {
        font-size: 1.2em;
      }
    }

    h5 {
      font-family: $base-font-family;
      font-size: 1.1em;
      font-weight: 500;
    }

    h6 {
      font-family: $base-font-family;
      font-size: 1em;
      font-weight: 400;
    }
    
    &:last-child{
        margin-bottom: 0;
    }
}
