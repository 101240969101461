/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
  margin: 0;
  padding: 0;
}

/* ubuntu-300 - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/ubuntu-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/ubuntu-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/ubuntu-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/ubuntu-v20-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/ubuntu-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/ubuntu-v20-latin-300.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

/* ubuntu-300italic - latin */
@font-face {
  font-family: 'Ubuntu';
  font-style: italic;
  font-weight: 300;
  src: url('../fonts/ubuntu-v20-latin-300italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/ubuntu-v20-latin-300italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/ubuntu-v20-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/ubuntu-v20-latin-300italic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/ubuntu-v20-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/ubuntu-v20-latin-300italic.svg#Ubuntu') format('svg'); /* Legacy iOS */
}

/**
 * Basic styling
 */
body {
  font-family: $base-font-family;
  font-size: $base-font-size;
  line-height: $base-line-height;
  font-weight: 300;
  color: $text-color;
  -webkit-text-size-adjust: 100%;
  background-color: $background-color-dark;
  @include centered-gradient(darken($background-color, 1%), $background-color-dark);
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
  margin-bottom: $spacing-unit / 3;
}

h1 {
  margin-bottom: $spacing-unit / 2;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: $spacing-unit;
  font-family: $brand-font-family;
  line-height: $header-line-height
}

h1, h4, h6 {
  color: $brand-color;
}


hr {
  border: 0;
  height: 1px;
  background-color: $background-color;
  //margin: $spacing-unit / 1.5 0;
  @include centered-gradient($background-color, $link-color);
}

/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}


/**
 * Figures
 */
figure > img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}


/**
 * Lists
 */
ul, ol {
  margin-left: $spacing-unit;
}

li {
  > ul,
  > ol {
    margin-bottom: 0;
  }
}


/**
 * Headings
 */
h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
}


/**
 * Links
 */
a {
  color: $link-color;
  text-decoration: none;

  &:visited {
    color: $link-color;
  }

  &:hover {
    color: darken($link-color, 15%);
    text-decoration: underline;
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $grey-color;
  border-left: 4px solid $grey-color-light;
  padding-left: $spacing-unit / 2;
  font-size: 18px;
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}


/**
 * Code formatting
 */
pre,
code {
  font-size: 15px;
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  background-color: lighten($grey-color-light, 10);
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: scroll;

  > code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}


/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
  }

  //border: 1px solid;
}


/**
 * Clearfix
 */
%clearfix {

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


.hidden {
  display: none;
}


/**
 * Icons
 */
.icon {

  > svg {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    path {
      fill: $grey-color;
    }
  }
}

#back-top {
  @include border-top-radius(9px);
  @include transition(all, 0.3s, ease-in-out);
  @include box-shadow(0px, 0px, 16px, #999);
  //@include opacity(0.8);
  font-size: 0.9em;
  border: 1px solid #fff;
  position: fixed;
  bottom: 0;
  right: 2%;
  padding: 4px 6px 2px;
  cursor: pointer;
  background: $brand-color;
  color: #fff;

  &:hover {
    @include transition(all, 0.3s, ease-in-out);
  }
}

