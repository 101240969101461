ul.service-list {
  list-style: none;
  margin: 0;
  font-size: 1.2em;
  line-height: 1.15em;
  li {
    margin-top: $spacing-unit / 1.5 ;
    ul {
      font-size: 0.8em;  
      padding-left: 0px;
      margin: 0;
      li {
        font-size: 1em;
        list-style: none;
        font-weight: 300;
        line-height: 1.3em;
        margin: 6px 0;
        padding-left: 1.4em;
        text-indent: -1.4em;
        &:before {
            content: "";
            line-height: 1em;
            width: .8em;
            height: .8em;
            background-color: #ddd;
            float: left;
            margin: .15em 2em 0 -1.4em;
            //border-radius: 50%;         
        }
      }      
    }
  }  
}